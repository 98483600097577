
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Utils } from '@/scripts/Utils';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import { Classroom } from '@/interfaces/Classroom.entity';
  import RubricSumCard from '@/components/RubricSumCard.component.vue';

/**
 * @desc page d'affichage des rubriques archivées
 */
  @Component({
    components: {
      'rubric-sum-card' : RubricSumCard
    },
  })
  export default class Archives extends Vue {
    displayedRubrics : Array<Rubric> = [];
    classrooms : Array<Classroom> = [];
    projects : Array<Rubric> = [];

    private dataToggle = 0;
    private mobileClass = '';

    mounted()  : void {
      this.loadArchivedRubrics();
      this.mobileClass = (this as any).$mq === 'mobile' || (this as any).$mq === 'tablet' ? 'mobile' : '';
    }

    /**
     * @desc charge les rubriques archivées
     */
    loadArchivedRubrics() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllArchivedRubrics, headers)
        .then((response) => {
          const responseArray = response.data;
          for(let i=0; i < responseArray.length; ++i) {
            if(responseArray[i].type === Consts.rubric_type_schoolProject) {
              this.projects.push(responseArray[i]);
            }
            else if(responseArray[i].type === Consts.rubric_type_classroom) {
              this.classrooms.push(responseArray[i]);
            }
          }
          this.displayedRubrics = this.classrooms;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc met à jour les rubriques affichées -> soit classes, soit projets d'école
     */
    private updateDisplayedRubrics() : void {
      if(this.dataToggle === 1) { // projets d'école
        this.displayedRubrics = this.projects;
      }
      else {
        this.displayedRubrics = this.classrooms;
      }
    }

    /**
     *  @desc demande la mise à jour des rubriques affichées
     */
    private dataToggleChanged() : void {
      this.updateDisplayedRubrics();
    }
  }
